var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2c11153c8c934744c2c76d25371fb5929f21beb9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 |-----------------------------------------------------------------------------
 | sentry.client.config.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | This file configures the initialization of Sentry on the browser. The config
 | you add here will be used whenever a page is visited.
 |
 */

import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENV !== 'development') {
	LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);

	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://c2bb1c3886fa4a2b854fd1f875d72c47@o1039980.ingest.sentry.io/6188273',
		environment: process.env.NEXT_PUBLIC_ENV,
		tracesSampleRate: 1.0,

		beforeSend(e) {
			const logRocketSession = LogRocket.sessionURL;

			if (logRocketSession !== null) {
				e.extra['LogRocket'] = logRocketSession;
				return e;
			} else {
				return e;
			}
		},
	});
} else {
	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://c2bb1c3886fa4a2b854fd1f875d72c47@o1039980.ingest.sentry.io/6188273',
		environment: process.env.NEXT_PUBLIC_ENV,
		tracesSampleRate: 1.0,
	});
}
