/*
 |-----------------------------------------------------------------------------
 | pages/_app.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';

import Loader from '@/molecules/Loader';
import { store } from '@/app/store';

import 'lazysizes';
import 'styles/global.scss';

const MyApp = ({ Component, pageProps }: AppProps) => {
	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
				/>

				<link rel="preconnect" href="https://res.cloudinary.com" />
				<link rel="dns-prefetch" href="https://res.cloudinary.com" />

				<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>

				<link
					rel="icon"
					type="image/png"
					sizes="48x48"
					href="/favicon-48x48.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="192x192"
					href="/favicon-192x192.png"
				/>

				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="167x167"
					href="/favicon-167x167.png"
				/>
				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="180x180"
					href="/favicon-180x180.png"
				/>

				<link rel="author" href="/humans.txt" />
			</Head>
			<Provider store={store}>
				{/*<Loader />*/}
				<Component {...pageProps} />
			</Provider>
		</>
	);
};

export default MyApp;
